import React from 'react';
import { Box, Container, Typography} from '@mui/material';

export const ErrorPage = () => {
    return (
        <Container maxWidth="md" style={{ textAlign: 'center', marginTop: '50px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              padding: '40px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography variant="h5" color="textPrimary" gutterBottom>
              페이지를 찾을 수 없습니다.
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
                요청하신 페이지가 존재하지 않거나, 잘못된 경로로 이동하셨습니다. <br />
                URL을 확인하시고 다시 시도해 주세요.
            </Typography>

          </Box>
        </Container>
    )
}