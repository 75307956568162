import React, {useEffect, useState, useRef} from "react";
 import {useNavigate} from "react-router-dom";
import {
    Container,
    Grid, InputAdornment,
    Paper, TextField, Typography, IconButton
} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import SearchIcon from '@mui/icons-material/Search';
import Loading from "../Layout/Loading";
import {HistoryListArrayType, HistoryList} from "../../api/historyAPI";

function HistoryConfig() {

    const [loading, setLoading] = useState<boolean>(true);
    const [historyData, setHistoryData] = useState<HistoryListArrayType>([]);
    const [total_count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const isFirstRender = useRef(true);

    const navigator = useNavigate();
    const historyColumns: GridColDef[] = [
        { field: 'id', hide: true},
        { field: 'name', headerName: '프로세스 이름', flex: 2},
        { field: 'start', headerName: '시작시간', flex: 1 },
        { field: 'end', headerName: '종료시간', flex:1},
        { field: 'status', headerName: '상태', flex:1},
        { field: 'count', headerName: '수집건수', flex:1},
        { field: 'writer', headerName: '사용자', flex:1}
    ]

    const handleHistoryListFetch = (isPageload: boolean) => {
        if (isPageload) {
            setLoading(true);
        }else{
            setLoading(false);
        }

        HistoryList(page, size, searchKeyword).then(data => {
            setHistoryData(data.data);
            setCount(data.total);
            setLoading(false);
        });

    }

    const handleHistorySearch = () => {
        setPage(0);
        setSearchKeyword(searchValue);
    }

    useEffect(() => {
        handleHistoryListFetch(true);
        console.log("1");
        console.log(isFirstRender.current);
        // setLoading(true);
        // HistoryList(page, size).then(data => {
        //     setHistoryData(data.data);
        //     setCount(data.total);
        //     setLoading(false);
        // })
        }, []);

    useEffect(() => {
        if (isFirstRender.current){
            isFirstRender.current = false;
            return;
        }
        handleHistoryListFetch(false);
        // setLoading(true);
        // HistoryList(page, size).then(data => {
        //     setHistoryData(data.data);
        //     setCount(data.total);
        //     setLoading(false);
        // })
        }, [size, page, searchKeyword]);

     if (loading) return <Loading />;


    return (
        <Container maxWidth='xl' sx={{mt:10, bm:2}}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}
                      sx={{
                          p:0,
                          display:'flex',
                          flexDirection:'row',
                          height:'10%',
                      }}>
                    <Paper sx={{
                        p: 1,
                        display: 'flex',
                        width: '100%',
                    }}>
                        <Grid container spacing={1}>
                            <Grid item lg={10} md={10} xs={10} sx={{
                                display : 'flex',
                                alignItems: 'center',
                            }}>
                                <Typography sx={{ml:1}} variant='h5'>데이터 수집 이력 관리</Typography>
                            </Grid>
                            <Grid item lg={2} md={2} xs={2} sm={2}>
                                <TextField
                                    fullWidth
                                    id="searchBar"
                                    label="수집 이력 검색"
                                    size="small"
                                    value={searchValue}
                                    onChange={e => setSearchValue(e.target.value)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            handleHistorySearch()
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleHistorySearch}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}
                    sx = {{
                        mt:1,
                        p:0,
                        display:'flex',
                        flexDirection:'row',
                        height:'90%'
                    }}>
                    <Paper
                        sx={{
                            p:1,
                            display:'flex',
                            height:650,
                            width:'100%',
                        }}>
                        <DataGrid
                            columns={historyColumns}
                            rows={historyData}
                            page={page}
                            pageSize={size}
                            rowCount={total_count}
                            paginationMode="server"
                            onPageChange={(newPage) => setPage(newPage)}
                            onPageSizeChange={(newSize) => setSize(newSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                        />
                    </Paper>
                </Grid>
            </Grid>

        </Container>
    )
}

export default HistoryConfig;