import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Toolbar,
    IconButton,
    Typography
} from '@mui/material';
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import {mappingBaseInterface} from "../../api/definition";

export function ConfirmDialog(props: any){

    const handleDialogAction = () => {
        props.handleDialogAction();
    }

    const handleDialogClose = () => {
        props.handleDialogClose();
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogClose}
        >
            <DialogTitle id='status-dialog'>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='status-dialog-description'>
                    {props.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogAction}>예</Button>
                <Button onClick={handleDialogClose}>아니오</Button>
            </DialogActions>
        </Dialog>
    );
}

export function LayerDialog(props: any){

    const handleDialogClose = () => {
        props.handleDialogClose();
    }

    return (
        <Dialog
            fullScreen
            open={props.open}
            onClose={handleDialogClose}
        >
            <Toolbar>
                <IconButton
                    edge='start'
                    color='error'
                    onClick={handleDialogClose}
                    aria-label='close'
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <DialogTitle id='status-dialog'>
                {props.title}
            </DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
        </Dialog>
    );
}

export function CreateTableCustomDialog(props:any){

    const keys = props.id.split(',');
    const handleDialogAction = () => {
        props.handleDialogAction();
    }

    const handleDialogClose = () => {
        props.handleDialogClose();
    }

    return (
        <Dialog open={props.open}
                onClose={handleDialogClose}
                maxWidth="md"
                fullWidth>
        <DialogTitle id="alert-dialog-title" align="center">
          테이블을 생성하시겠습니까?
        </DialogTitle>
        <DialogContent>
            <TableContainer component={Paper} style={{ maxWidth: 600, margin: 'auto', marginTop: '20px' }}>
                  <Typography variant="h6" align="center" gutterBottom>
                    테이블 명: {props.title}
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>컬럼명</strong></TableCell>
                        <TableCell><strong>타입</strong></TableCell>
                        <TableCell><strong>기본키 여부</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.mapping.map((item: mappingBaseInterface, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{item.output_name}</TableCell>
                          <TableCell>{item.type}</TableCell>
                          <TableCell>{keys.includes(item.input_name) ? "기본키" : "-"}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleDialogAction}>예</Button>
          <Button onClick={handleDialogClose}> 아니오</Button>
        </DialogActions>
        </Dialog>
    )
}