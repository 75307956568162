import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import {
    Button,
    Container,
    Grid, Paper,
    Typography, SelectChangeEvent, TextField, Select, MenuItem
} from "@mui/material";

import {RepositoryUpdate} from '../../api/configAPI';

import {
    repositoryInterface,
    instanceOfRepositoryInterface,
    CreateRepository,
    UpdateRepository,
    CheckRepository
} from '../../api/repositoryAPI';

import {
    repositoryInitial,
    repositoryESHostInitial,
    repositoryLRSHostInitial,
    repositoryHostInitial,
    repositoryAPIHostInitial
} from "../../api/definition";
import {RepositoryHostInput} from "./Input/RepositoryBaseHost";
import {RepositoryESHostInput} from "./Input/RepositoryESHost";
import {RepositoryAPIHostInput} from "./Input/RepositoryAPIHost";
import {RepositoryLRSHostInput} from "./Output/RepositoryLRSHost";
import {RepositoryESOutHost} from "./Output/RepositoryESOutHost";
import {RepositoryOutHostInput} from "./Output/RepositoryOutputBaseHost";

function RepositoryDetail(){

    const repositoryName = useLocation().state.repositoryName;
    const writer = useLocation().state.writer;
    const [update, setUpdate] = useState<boolean>(false);

    const [repositoryData, setData] = useState<repositoryInterface>(repositoryInitial());
    const navigate = useNavigate();

    useEffect(() => {
        if(repositoryName !== undefined && repositoryName !== 'new'){
            CheckRepository(repositoryName,writer).then(data => {
                if (instanceOfRepositoryInterface(data)){
                    setData(data);
                }else{
                    alert('data is not valid');
                }
                setUpdate(true);
            })
        }
    }, [repositoryName])

    const navigateRepositoryList = () => {
        let url = '/repository';
        navigate(url);
    }
    const handleRepositoryInput = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputKey = event.target.id;
        inputKey = inputKey.replace('input_', '');
        let value: string = event.target.value;
        setData({
            ...repositoryData,
            [inputKey]: value
        });
    };

    const handleRepositoryChangeInput = (event: SelectChangeEvent, field:string) =>{
        let value: string|boolean = event.target.value;
        if(value !== '' && value === 'activate'){
            value = true;
        }

        if(value !== '' && value === 'disabled'){
            value = false;
        }

        setData({
            ...repositoryData,
            [field]: value
        });
    }

    const handleInputTypeChange = (event: SelectChangeEvent) => {
        let data:repositoryInterface = repositoryData;
        data.input_type = event.target.value;
        data.input_hosts.length = 0;
        if(data.input_type ==='elasticsearch'){
            data.input_hosts.push(repositoryESHostInitial());
        }else if(data.input_type === 'api'){
            data.input_hosts.push(repositoryAPIHostInitial());
        }
        else{
            data.input_hosts.push(repositoryHostInitial());
        }

        setData({
            ...repositoryData,
            'input_type': data.input_type,
            'input_hosts': [...data.input_hosts]
        });

    }

    const handleOutputTypeChange = (event: SelectChangeEvent) => {
        let data:repositoryInterface = repositoryData
        data.output_type = event.target.value;
        data.output_hosts.length=0;
        if(data.output_type ==='elasticsearch'){
            data.output_hosts.push(repositoryESHostInitial());
        }else if(data.output_type == 'lrs'){
            data.output_hosts.push(repositoryLRSHostInitial());
        }else{
            data.output_hosts.push(repositoryHostInitial());
        }

        setData({
            ...repositoryData,
            'output_type': data.output_type,
            'output_hosts': [...data.output_hosts]
        });
    }

    const handleAddHost = (type: string) => {

        if(type === 'input'){
            if(repositoryData.input_type === 'elasticsearch'){
                setData({
                    ...repositoryData,
                    ['input_hosts']: [...repositoryData.input_hosts, repositoryESHostInitial()]
                });
            }else if(repositoryData.input_type === 'api'){
                setData({
                    ...repositoryData,
                    ['input_hosts']: [...repositoryData.input_hosts, repositoryAPIHostInitial()]
                });
            }
            else {
                setData({
                    ...repositoryData,
                    ['input_hosts']: [...repositoryData.input_hosts, repositoryHostInitial()]
                });
            }
        }else{
            if(repositoryData.output_type === 'lrs'){
                setData({
                    ...repositoryData,
                    ['output_hosts']: [...repositoryData.output_hosts, repositoryLRSHostInitial()]
                });
            }else{
                setData({
                    ...repositoryData,
                    ['output_hosts']: [...repositoryData.output_hosts, repositoryESHostInitial()]
                });
            }
        }

    }

    const handleCreateRepository = () => {
        if(repositoryData.name === ''){
            alert('저장소 이름을 입력해주세요.');
        }else {
            if (repositoryName !== undefined && repositoryName !== 'new') {
                UpdateRepository(repositoryData).then(value => {
                    RepositoryUpdate(repositoryData).then(value => {
                    });
                    alert(value);
                });
            } else {
                CreateRepository(repositoryData).then(value => {
                    alert(value);
                });
            }
        }
    }

    return (
        <Container maxWidth='xl' sx={{mt:10, mb:2 }}>
            <Grid item lg={12} md={12} xs={12} sx={{mt:2}}>
                <Grid container spacing={1}>
                    <Paper
                        sx={{
                            p: 1,
                            display: 'flex',
                            width:'100%',
                        }}>
                        <Grid item lg={6} md={6} sm={6} xs={6} sx={{
                                display:'flex',
                                alignItems:'center'
                        }}>
                            <Typography sx={{ml: 1}} variant='h6'>{update ? '저장소 수정':'새 저장소 생성'}</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6} sx={{
                            display:'flex',
                            flexDirection:'row',
                            justifyContent:'flex-end'
                        }}>
                            <Button
                                variant='contained'
                                onClick={navigateRepositoryList}
                                sx={{height : '100%', ml: 1}}
                                > 목록보기
                            </Button>
                            <Button
                                variant='contained'
                                onClick={handleCreateRepository}
                                sx={{height : '100%', ml: 1}}
                                > 저장하기
                            </Button>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sx={{mt:2}}>
                <Grid container spacing={1}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <TextField
                            required
                            fullWidth
                            disabled={update}
                            id='input_name'
                            label='Repository Name'
                            value={repositoryData.name}
                            onChange={handleRepositoryInput}
                        />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Select
                            required
                            fullWidth
                            id='input_status'
                            value={repositoryData.status ? 'activate' : 'disabled'}
                            onChange={e => handleRepositoryChangeInput(e, 'status')}>
                            <MenuItem value="activate">Activate</MenuItem>
                            <MenuItem value="disabled">Disabled</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:2}}>
                        <TextField
                            fullWidth
                            id='input_description'
                            label='Repository Description'
                            value={repositoryData.description}
                            onChange={handleRepositoryInput}
                        />
                    </Grid>
                </Grid>
            </Grid>
             <Grid item lg={12} md={12} xs={12} sx={{mt:2}}>
                 <Grid container spacing={1}>
                     <Paper
                         sx={{
                            p: 1,
                            display: 'flex',
                            width:'100%',
                            bgcolor: '#eeeeee',
                            mb: 1,
                            mt: 1
                         }}>
                         <Grid item lg={12} md={12} sm={12} xs={12}>
                             <Grid container spacing={1}>
                                 <Grid item lg={10} md={10} sm={10} xs={10}>
                                     <Typography variant={'subtitle1'}>
                                         Input Host - length : {repositoryData.input_hosts ? repositoryData.input_hosts.length : 0}
                                     </Typography>
                                 </Grid>
                                 <Grid item lg={2} md={2} sm={2} xs={2}
                                        sx={{
                                        display:'flex',
                                        flexDirection:'row',
                                        justifyContent:'flex-end'
                                    }}
                                   >
                                     <Button
                                        variant='outlined'
                                        onClick={e => handleAddHost('input')}
                                        sx={{mr:0}}>
                                        Host 추가
                                     </Button>
                                 </Grid>
                             </Grid>
                         </Grid>
                     </Paper>
                 </Grid>
             </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:1}}>
                        <Grid container spacing={1}>
                             <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Select
                                    required
                                    fullWidth
                                    id='input_type'
                                    value={repositoryData.input_type}
                                    onChange={e => handleInputTypeChange(e)}>
                                    <MenuItem value="mysql">Mysql</MenuItem>
                                    <MenuItem value="mssql">Mssql</MenuItem>
                                    {/*<MenuItem value="elasticsearch" disabled>Elasticsearch</MenuItem>*/}
                                    <MenuItem value="postgresql">PostgreSQL</MenuItem>
                                    <MenuItem value="tibero">Tibero</MenuItem>
                                    <MenuItem value="api">API</MenuItem>
                                </Select>
                             </Grid>
                        </Grid>
                    </Grid>
                    { (repositoryData.input_type === 'mysql' || repositoryData.input_type === 'mssql' ||
                        repositoryData.input_type === 'tibero' ||repositoryData.input_type === 'postgresql') &&
                        <RepositoryHostInput value={repositoryData}
                                             handleChangeHost={setData}>
                        </RepositoryHostInput>
                    }
                    {repositoryData.input_type === 'elasticsearch' &&
                        <RepositoryESHostInput value={repositoryData}
                                             handleChangeHost={setData}>
                        </RepositoryESHostInput>
                    }
                    {repositoryData.input_type === 'api' &&
                        <RepositoryAPIHostInput value={repositoryData}
                                                handleChangeHost={setData}>

                        </RepositoryAPIHostInput>
                    }
                </Grid>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sx={{mt:2}}>
                 <Grid container spacing={1}>
                     <Paper
                         sx={{
                            p: 1,
                            display: 'flex',
                            width:'100%',
                            bgcolor: '#eeeeee',
                            mb: 1,
                            mt: 1
                         }}>
                         <Grid item lg={6} md={6} sm={6} xs={6}>
                             <Typography variant={'subtitle1'}>
                                         Output Host - length : {repositoryData.output_hosts ? repositoryData.output_hosts.length : 0}
                             </Typography>
                         </Grid>
                         <Grid item lg={6} md={6} sm={6} xs={6}
                               sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    justifyContent:'flex-end'
                                    }}
                                   >
                                     <Button
                                        variant='outlined'
                                        onClick={e => handleAddHost('output')}
                                        >
                                        Host 추가
                                     </Button>
                         </Grid>
                     </Paper>
                 </Grid>
             </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:1}}>
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={3} xs={3}>
                                        <Select
                                            required
                                            fullWidth
                                            id='output_type'
                                            value={repositoryData.output_type}
                                            onChange={e => handleOutputTypeChange(e)}>
                                            <MenuItem value="postgresql">PostgreSQL</MenuItem>
                                            <MenuItem value="elasticsearch">Elasticsearch</MenuItem>
                                            <MenuItem value="lrs">LRS</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                            { repositoryData.output_type === 'postgresql' &&
                                <RepositoryOutHostInput value={repositoryData}
                                                        handleChangeHost={setData}>
                                </RepositoryOutHostInput>
                            }
                            { repositoryData.output_type === 'elasticsearch' &&
                                <RepositoryESOutHost value={repositoryData}
                                                        handleChangeHost={setData}>
                                </RepositoryESOutHost>
                            }
                            { repositoryData.output_type === 'lrs' &&
                                <RepositoryLRSHostInput value={repositoryData}
                                                        handleChangeHost={setData}>
                                </RepositoryLRSHostInput>
                            }
                        </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{
                            display:'flex',
                            flexDirection:'row',
                            justifyContent:'flex-end',
                            mt:2
                        }}>
                            <Button
                                variant='contained'
                                onClick={navigateRepositoryList}
                                sx={{height : '100%', ml: 1}}
                                > 목록보기
                            </Button>
                            <Button
                                variant='contained'
                                onClick={handleCreateRepository}
                                sx={{height : '100%', ml: 1}}
                                > 저장하기
                 </Button>
            </Grid>
        </Container>
    )
}

export default RepositoryDetail