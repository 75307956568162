import TISAxios from './Instance';
import {
    inputBaseInterface,
    inputESInterface,
    inputAPIInterface,
    outputLRSInterface,
    outputESInterface,
    mappingBaseInterface,
    mappingLRSInterface, outputBaseInterface,
} from "./definition";
import {GridCellValue, GridSelectionModel} from "@mui/x-data-grid";
import {repositoryArrayType,repositoryInterface} from "./repositoryAPI";

interface configListInterface {
    id: string;
    name: string;
    cron: string;
    description:string;
    input_type: string;
    output_type: string;
    repository : string;
    writer:string;
}

interface configWithStatusInterface extends configListInterface{
    status: string;
}

export type configArrayType = Array<configListInterface>;
export type configWithStatusArrayType = Array<configWithStatusInterface>;


export interface configInterface {
    name: string;
    cron: string;
    description:string;
    input_type: string;
    input: inputBaseInterface | inputESInterface | inputAPIInterface;
    output_type: string;
    output: outputBaseInterface | outputESInterface | outputLRSInterface;
    mapping: Array<mappingBaseInterface> | mappingLRSInterface;
    repository:repositoryArrayType;
    status:boolean;
    writer:string;
}


export function instanceOfConfigInterface(object: any): object is configInterface {
    return object.name !== undefined;
}

export function isInputAPIInterface(input: inputBaseInterface | inputESInterface | inputAPIInterface): input is inputAPIInterface {
    return ((input as inputAPIInterface).config !== undefined);
}

export function hasId(input: inputBaseInterface | inputESInterface | inputAPIInterface): input is inputBaseInterface | inputESInterface {
  return (input as inputBaseInterface).id !== undefined;
}

export function hasOutputTable(output: outputBaseInterface | outputESInterface | outputLRSInterface): output is outputBaseInterface {
  return (output as outputBaseInterface).table !== undefined;
}


interface messageObejctInterface {
    name: string,
    success: string,
    error: string
}

export async function CreateConfig(data: configInterface) {
    let message: messageObejctInterface = {
        name: '',
        success: 'message',
        error: 'error'
    }
    await TISAxios.post(
        `/tis/config/create`, data
    ).then(function (response) {
        if (response.data !== undefined) {
            message.success = response.data.toString();
        }
    }).catch(function (error) {
        message.name = error.name;
        message.error = error.error;

    });
    return message;
}

export async function DeleteConfig(name: string, writer: string) {
    let message: string = 'error';

    await TISAxios.post(
        `/tis/config/delete`,
        {name: name, writer: writer}
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });

    return message;
}

export async function UpdateConfig(data: configInterface) {
    let message: string = 'error';

    await TISAxios.post(
        `/tis/config/update`, data
    ).then(function (response) {
        if (response.data !== undefined) {
            message = response.data.toString();
        }
    }).catch(function (error) {
        message = error;
    });
    return message;
}

export async function CheckConfig(name: string, writer:string) {
    let data: configInterface | string = 'error';
    await TISAxios.post(
        `/tis/config/check`, {name: name, writer:writer}
    ).then(function (response) {
        if (response.data !== undefined) {
            data = response.data[0];
        }
    }).catch(function (error) {
        data = error;
    });
    return data;
}

export async function ListConfig() {
    const newData: configArrayType = [];
    await TISAxios.post(
        `/tis/config/list`,
    ).then(function (response) {
        if (response.data !== undefined) {
            response.data.forEach((row: { _id: string; name: string; cron: string; description:string; input_type: string; output_type: string; repository:repositoryArrayType; writer:string }) => {
                let repository = '';
                if(row.repository.length === 1){
                    repository = row.repository[0].name;
                }else{
                    const cnt = row.repository.length;
                    repository = row.repository[0].name + ' 외 ' + cnt;
                }

                newData.push({
                    'id': row._id,
                    'name': row.name,
                    'cron': row.cron,
                    'description' : row.description,
                    'input_type': row.input_type,
                    'output_type': row.output_type,
                    'repository' : repository,
                    'writer':row.writer
                });
            });
        }
    }).catch(function (error) {

    });

    return newData;
}

export async function ListConfigWithStatus() {
    const newData: configWithStatusArrayType = [];
    const statusMap: Map<string, string> = new Map<string, string>();

    await TISAxios.post(
        `/tis/process/status`,
    ).then(function (response) {
        if (response.data !== undefined) {
            response.data.forEach((row: { name: string; status: string; }) =>
                statusMap.set(row.name, row.status)
            );
        }
    }).catch(function (error) {

    });

    await TISAxios.post(
        `/tis/config/activate_list`, {}
    ).then(function (response) {
        if (response.data !== undefined) {
            response.data.forEach((row: { _id: string; name: string; cron: string; description:string; input_type: string;output_type: string; repository: repositoryArrayType; writer:string}) => {
                let status: string;
                let getStatus: string | undefined = statusMap.get(row.name + '-' + row.writer);
                if (getStatus === undefined) {
                    status = 'ready';
                }else {
                    status = getStatus;
                }

                    let repository = '';
                    if(row.repository.length === 1){
                        repository = row.repository[0].name;
                    }else{
                        const cnt = row.repository.length;
                        repository = row.repository[0].name + ' 외 ' + cnt;
                    }

                    newData.push({
                        'id': row._id,
                        'name': row.name,
                        'cron': row.cron,
                        'description': row.description,
                        'input_type': row.input_type,
                        'output_type': row.output_type,
                        'repository': repository,
                        'status': status,
                        'writer': row.writer,
                    });

            })
        }
    }).catch(function (error) {

    });

    return newData;
}

export async function ConvertMapping(mapping: object) {
    let data: string | string = 'error';
    await TISAxios.post(
        `/tis/test/lrs_mapping`, {mapping: mapping}
    ).then(function (response) {
        if (response.data !== undefined) {
            data = JSON.stringify(response.data, null, 4);
        }
    }).catch(function (error) {
        data = error;
    });
    return data;
}

export async function MappingGuide() {
    let data: string | string = 'error';
    await TISAxios.post(
        `/tis/test/lrs_guide`, {}
    ).then(function (response) {
        if (response.data !== undefined) {
            data = JSON.stringify(response.data, null, 4);
        }
    }).catch(function (error) {
        data = error;
    });
    return data;
}

// 상태 가져오기
export async function ActivateProcess() {
    let data:GridSelectionModel = []
    await TISAxios.post(
        `/tis/config/activate_list`, {}
    ).then(function (response) {
        if (response.data !== undefined) {
            const activate_process = response.data;

            activate_process.forEach((row:{name: string, _id:string}) => {
                data.push(row._id);
            });

        }
    }).catch(function (error) {

    });
    return data;
}
// 상태변경
export async function StatusUpdate(activateModels:configListInterface[], disabledModels:configListInterface[]) {
    let data: string | string = 'error';
    await TISAxios.post(
        `/tis/config/state_update`, {activate_list:activateModels, disabled_list:disabledModels}
    ).then(function (response) {
        if (response.data !== undefined) {
            data = response.data;
        }
    }).catch(function (error) {
        data = error;
    });
    return data;
}

export async function RepositoryUpdate(data : repositoryInterface){
    let message: string = 'error';
    await TISAxios.post(
        '/tis/config/repository_update', data
    ).then(function(response){
        if(response.data !== undefined){
            message = response.data.toString();
        }
    }).catch(function (error){
        message = error;
    })
    return message;
}

export async function RunConfigCheck(name: GridCellValue, writer:GridCellValue) {
    let message:boolean = false;
    await TISAxios.post(
        '/tis/process/check',
        {name:name, writer:writer}
    ).then(function(response){
        if(response.data !== undefined){
            message = response.data;
        }
    }).catch(function(error){
        message = error;
    })

    return message;
}

export async function CreateTable(data: configInterface) {
    let message: messageObejctInterface = {
        name: '',
        success: 'message',
        error: 'error'
    }
    await TISAxios.post(
        `/tis/config/create-table`, data
    ).then(function (response) {
        if (response.data !== undefined) {
            message.success = response.data.toString();
        }
    }).catch(function (error) {
        message.name = error
        message.error = error;

    });
    return message;
}