import React, {useEffect, useState} from 'react';
import {
    Dialog,
    Container,
    Paper,
    Button,
    Grid, Typography
} from "@mui/material";
import {DataGrid, GridColDef, GridRowId, GridSelectionModel} from "@mui/x-data-grid";
import {repositoryArrayType} from "../../api/repositoryAPI";


function RepositorySelectBox(props: any) {

    const [selectRepository, setSelectRepository] = useState<repositoryArrayType>([]);
    const [selectRepositoryModel, setSelectRepositoryModel] = useState<GridSelectionModel>([]);

    const repositoryColumns: GridColDef[] = [
        {field: 'id', hide: true},
        {field: 'name', headerName: 'Repository', flex: 2},
        {field: 'input_type', headerName: 'Input_Type', flex: 1},
        {field: 'input_host', headerName: 'Input Host', flex: 1},
        {field: 'output_type', headerName: 'Output Type', flex:1},
        {field: 'output_host', headerName: 'Output Host', flex:1}
    ]

    const isRepositoryTypeElementEqual = (arr:Array<string>) => {
        return arr.every(item => item === arr[0]);
    }
    const handleRepositorySelect = () => {
        let inputTypes = selectRepository.map(obj => obj.input_type)
        let outputTypes = selectRepository.map(obj => obj.output_type)
        const isInputEqual = isRepositoryTypeElementEqual(inputTypes);
        const isOutputEqual = isRepositoryTypeElementEqual(outputTypes);

        if(selectRepository.length === 0){
            alert('저장소를 선택해주세요.');
        }else if(isInputEqual && isOutputEqual){
            props.setRepositoryMapping(selectRepository);
            props.setInputTypeChange(inputTypes[0]);
            props.setOutputTypeChange(inputTypes[0], outputTypes[0]);
            setSelectRepository([]);
            props.setOpen(false);
        }else{
            alert('같은 타입을 선택해주세요.');
        }

    }

    const handleRepositoryCheck = (ids: GridSelectionModel) => {
        setSelectRepositoryModel(ids);
        const filterRepository = props.repositoryList.filter((data: { id: GridRowId; }) => ids.includes(data.id));
        setSelectRepository(filterRepository);
    }

    const handleRepositoryClose = () => {
        props.setOpen(false);
    }

    useEffect(() => {
        let selectModels:GridSelectionModel = [];
        let selectRepository:repositoryArrayType = [];
        props.repository.forEach((data:any) => {
            if('_id' in data){
                selectModels.push(data._id);
            }else{
                selectModels.push(data.id);
            }
            selectRepository.push(data);
        })
        setSelectRepository(selectRepository);
        setSelectRepositoryModel(selectModels);
    }, [props.open])

    return (
        <Dialog
                open={props.open}
                maxWidth='xl'
                fullWidth>
            <Container maxWidth='xl' sx={{mt:2, mb:2}}>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{
                        p:0,
                        display:'flex',
                        flexDirection:'row',
                        height:'10%',
                    }}>
                        <Grid container spacing={1}>
                            <Paper
                                sx={{
                                    p: 1,
                                    display:'flex',
                                    width:'100%'
                                }}
                                >
                                <Grid item lg={6} md={6} sm={6} xs={6} sx={{
                                    display:'flex',
                                    alignItems:'center',
                                }}>
                                     <Typography sx={{ml:1}} variant='h6'>저장소 선택</Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    justifyContent:'flex-end',
                                }}>
                                    <Button
                                        variant='contained'
                                        onClick={handleRepositorySelect}
                                        sx={{
                                            height:'100%',
                                            boxShadow:"none",
                                            mr:1
                                         }}>
                                        선택하기
                                    </Button>
                                    <Button
                                        variant='contained'
                                        onClick={handleRepositoryClose}
                                        sx={{
                                            height:'100%',
                                            boxShadow:"none",
                                         }}>
                                         닫기
                                    </Button>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Container maxWidth={'xl'}>
                                <Paper
                                    sx={{
                                        p: 1,
                                        display: 'flex',
                                        height:700,
                                        width:'100%',
                                    }}>
                                <DataGrid
                                    columns={repositoryColumns}
                                    rows={props.repositoryList}
                                    pageSize={10}
                                    checkboxSelection
                                    selectionModel={selectRepositoryModel}
                                    onSelectionModelChange={ids => handleRepositoryCheck(ids)}
                                />
                                </Paper>
                           </Container>
                        </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}

export default RepositorySelectBox;