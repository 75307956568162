// input: mysql, mssql
export interface inputBaseInterface {
    id:string;
    since: string;
    query: string;
}

export function inputBaseInitial(){
    return {
        id: '',
        since: '',
        query: '',
    }
}

// input: elasticsearch
export interface inputESInterface {
    index: string;
    id: string;
    since: string;
    query: string;
}

export function inputESInitial(){
    return {
        index: '',
        id: '',
        since: '',
        query: '',
    }
}

// input: api
export interface inputAPIInterface {
    config: inputAPIConfigInterface;
    authorization: inputAPIAuthorizationInterface;
    headers: Array<inputAPIParamsInterface>;
    params: Array<inputAPIParamsInterface>;
}

export function inputAPIInitial() {
    return {
        config: inputAPIConfigInitial(),
        authorization: inputAPIAuthorizationInitial(),
        headers: [inputAPIParamsInitial()],
        params: [inputAPIParamsInitial()],
    }
}

export interface inputAPIConfigInterface {
    request_type: string;
    response_type: string;
    id: string;
    since: string;
    query: string;
}

export function inputAPIConfigInitial() {
    return {
        request_type : '',
        response_type: '',
        id: '',
        since: '',
        query: ''
    }
}

export interface inputAPIAuthorizationInterface {
    auth_type: string;
    auth: inputAPIAuthorizationBasicAuthInterface | inputAPIAuthorizationBearerTokenInterface;
}

export function inputAPIAuthorizationInitial() {
    return {
        auth_type: 'No Auth',
        auth: inputAPIAuthorizationBasicAuthInitial(),
    }
}
export interface inputAPIAuthorizationBasicAuthInterface {
    username: string;
    password: string;
}

export function inputAPIAuthorizationBasicAuthInitial(){
    return {
        username: '',
        password: '',
    }
}

export interface inputAPIParamsInterface {
    name: string;
    description: string;
    type: string;
    value: string;
}

export function inputAPIParamsInitial() {
    return {
        name: '',
        description: '',
        type: 'text',
        value: '',
    }
}

export interface inputAPIAuthorizationBearerTokenInterface {
    token: string;
}

export function inputAPIAuthorizationBearerTokenInitial() {
    return {
        token: ''
    }
}
// output: elasticsearch
export interface outputESInterface {
    index: string;
}

export function outputESInitial() {
    return {
        index: '',
    }
}

export interface outputBaseInterface {
    table: string;
}

export function outputBaseInitial() {
    return {
        table: '',
    }
}

// output: host
// export interface hostInterface {
//     host: string;
//     port: string;
// }
//
// export function hostInitial() {
//     return {
//         host: '',
//         port: ''
//     }
// }

// output: lrs
export interface outputLRSInterface {
    id_column: string;
    prefix: string;
    version: string;
}

export function outputLRSInitial() {
    return {
        id_column: '',
        prefix: '',
        version: '1.0.3'
    }
}

// mapping - base
export interface mappingBaseInterface {
    input_name: string;
    output_name: string;
    type: string;
    splitter: string;
}

export function mappingBaseInitial () {
    return {
        input_name: '',
        output_name: '',
        type: '',
        splitter: ''
    }
}

// mapping - LRS
export interface mappingLRSInterface {
    id: string;
    timestamp: string;
    actor: object;
    verb: object;
    object: object;
    // result: Array<actorLRSInterface>;
    // context: Array<actorLRSInterface>;
    // attachments: Array<actorLRSInterface>;
}

export function mappingLRSInitial () {
    return {
        id: '',
        timestamp: '',
        actor: actorLRSInitial(),
        verb: verbLRSInitial(),
        object: objectLRSInitial(),
        // result: {},
        // context: {},
        // attachments: {},
    }
}

export interface actorLRSInterface {
    name: string;
    objectType: string;
    ifi: object;
}

export function actorLRSInitial (objectType: string = 'Agent') {
    return {
        name: '',
        objectType: objectType,
        ifi: actorIFIInitial()
    }
}

export function actorIFIInitial () {
    return {property: '', homepage: '', name: ''}
}

export interface verbLRSInterface {
    id: string;
    display: Array<propertyInterface>;
}

export function verbLRSInitial () {
    return {
        id: '',
        display: [propertyInitial ()]
    }
}

export interface objectLRSInterface {
    objectType: string;
    id: string;
    definition: object;
    name: string;
    ifi: object;
    actor: object;
    verb: object;
    object: object;
}

export function objectLRSInitial () {
    return {
        objectType: 'Activity',
        id: '',
        definition: objectActivityInitial(),
        name: '',
        ifi: actorIFIInitial (),
        actor: actorLRSInitial(),
        verb: verbLRSInitial(),
        object: objectSubStatementInitial()
    }
}

export function objectActivityInitial () {
    return {
        name: [],
        description: [],
        extensions: [],
        type: '',
        moreInfo: ''
    }
}

export function objectSubStatementInitial () {
    return {
        objectType: 'Activity',
        id: '',
        definition: objectActivityInitial(),
        name: '',
        ifi: actorIFIInitial (),
    }
}

export interface resultLRSInterface {
    score: object;
    success: boolean;
    completion: boolean;
    response: string;
    duration: string;
    extensions: Array<propertyInterface>;
}

export interface contextLRSInterface {
    registration: string;
    instructor: actorLRSInterface;
    team: actorLRSInterface;
    contextActivities: contextActivitiesInterface;
    revision: string;
    platform: string;
    language: string;
    statement: string;
    extensions: Array<propertyInterface>;
}

export interface contextActivitiesInterface {
    parent: contextActivityInterface;
    grouping: contextActivityInterface;
    category: contextActivityInterface;
    other: contextActivityInterface;
}

export interface contextActivityInterface {
    objectType: string;
    id: string;
    definition: object;
}

export interface attachmentsLRSInterface {
    usageType: string;
    display: Array<propertyInterface>;
    description: Array<propertyInterface>;
    contentType: string;
    length: number;
    sha2: string;
    fileUrl: string;
}

export function contextActivityInitial () {
    return {
        objectType: 'Activity',
        id: '',
        definition: objectActivityInitial()
    }
}

export function optionalLRSInitial (option: string) {
    if(option === 'result') {
        return {
            score: {},
            success: false,
            completion: false,
            response: '',
            duration: '',
            extensions: []
        }
    } else if(option === 'context') {
        return {
            registration: '',
            instructor: actorLRSInitial(),
            team: actorLRSInitial('Group'),
            contextActivities: {},
            revision: '',
            platform: '',
            language: '',
            statement: '',
            extensions: [propertyInitial()]
        }
    } else if(option === 'attachments') {
        return {
            usageType: '',
            display: [propertyInitial ()],
            description: [propertyInitial ()],
            contentType: '',
            length: 0,
            sha2: '',
            fileUrl: ''
        }
    }
}

export interface propertyInterface {
    id: string;
    value: string;
}

export function propertyInitial () {
    return {id: '', value: ''}
}

// 추가
export function repositoryInitial() {
    return {
        name : '',
        status : true,
        description : '',
        input_type : 'mysql',
        input_hosts : [repositoryHostInitial()],
        output_type : 'elasticsearch',
        output_hosts: [repositoryESHostInitial()],
        writer:''
    }
}

export function repositoryHostInitial() {
    return {
        host:'',
        port:'',
        username:'',
        password:'',
        database:''

    }
}

export function repositoryLRSHostInitial(){
    return {
        url:'',
        username:'',
        password:''
    }
}

export function repositoryESHostInitial(){
    return {
        host:'',
        port:'',
        username:'',
        password:''
    }
}

// 신규 API 저장소 추가
export interface repositoryAPIHostInterface {
    method: string;
    url: string;
}
export function repositoryAPIHostInitial() {
    return {
        method: 'GET',
        url: ''
    }
}

export function userInitial() {
    return {
        user_id: '',
        password: '',
        authority:'admin',
        status:false,
        user_name:'',
        user_email:'',
        user_phone:'',
        user_group:'',
        user_position:''
    }
}

export function userListInitial(){
    return {
        id: '',
        user_id: '',
        password: '',
        authority:'admin',
        status:'Activate',
        user_name:'',
        user_email:'',
        user_phone:'',
        user_group:'',
        user_position:''
    }
}

export interface userValidationInterface {
    user_id:Boolean;
    user_ps:Boolean;
    user_authority:Boolean;
    user_state:Boolean;
    user_name:Boolean;
    user_email:Boolean;
}

export function userValidationInitial(){
    return {
        user_id:true,
        user_ps:true,
        user_authority:true,
        user_state:true,
        user_name:true,
        user_email:true
    }
}

export interface userValidationTextInterface{
    user_id:string;
    user_ps:string;
    user_authority:string;
    user_state:string;
    user_name:string;
    user_email:string;
}

export function userValidationTextInitial(){
    return {
        user_id:'',
        user_ps:'',
        user_authority:'',
        user_state:'',
        user_name:'',
        user_email:''
    }
}

export function selectStartProcessInitial(){
    return {
        name: '',
        writer: ''
    }
}

export function SinceInitial() {
    return {
        id:'',
        writer: '',
        name:'',
        repository:'',
        host:'',
        database:'',
        since:'',
    }
}