import axios from 'axios';
import TISAxios from './Instance';

export async function executeJwtAuthenticationService(username: string, password: string) {
    let status: boolean = false;
    await axios.post(`/tis/security/authenticate`, {
        'username': username,
        'password': password
    }).then(function (response) {
        if (response.status === 200) {
            let accessToken: string = response.data.accessToken;
            let refreshToken: string = response.data.refreshToken;
            let authorization: string = response.data.authorization;
            let group: string = response.data.user_group;
            window.sessionStorage.setItem('accessToken', accessToken);
            window.sessionStorage.setItem('refreshToken', refreshToken);
            window.sessionStorage.setItem('authorization', authorization);
            window.sessionStorage.setItem('group', group);
            status = true;
        }
    }).catch(function (error) {
        if (error.response) {
            if (error.response.status === 403) {
                status = false;
            }
        }
    });
    return status;
}

export async function executeUpdateJwtAuthenticationService() {
    let status :boolean = false
    await TISAxios.post(`/tis/security/update_token`, {

    }).then(function (response) {
        if (response.status === 200) {
            let accessToken: string = response.data.accessToken;
            let refreshToken: string = response.data.refreshToken;
            let authorization: string = response.data.authorization;
            let group: string = response.data.user_group;
            window.sessionStorage.setItem('accessToken', accessToken);
            window.sessionStorage.setItem('refreshToken', refreshToken);
            window.sessionStorage.setItem('authorization', authorization);
            window.sessionStorage.setItem('group', group);
            status = true;
        }
    }).catch(function (error) {
        if (error.response) {
            if (error.response.status === 403) {
                status=false
            }
        }
    });
    return status;
}

export function isUserLoggedIn() {
    const accessToken: string | null = window.sessionStorage.getItem('accessToken');
    return accessToken != null;
}