import TISAxios from './Instance'

export interface HistroyListInterface{
    id: string;
    name: string;
    start: string;
    end: string;
    status: string;
    count: number;
    writer: string;
}

export type HistoryListArrayType = Array<HistroyListInterface>

// API 응답 구조 정의
interface HistoryApiResponse {
    total_count: number;
    list: Array<{
        id: string;
        name: string;
        start: string;
        end: string;
        status: boolean;
        count: number;
        writer: string;
    }>;
}


export async function HistoryList(page: number, size: number, keyword: string): Promise<{ data: HistoryListArrayType; total: number }> {
    try {
        const response = await TISAxios.post<HistoryApiResponse>('/tis/history/list', { page, size, keyword});

        // 데이터 변환
        const historyData: HistoryListArrayType = response.data.list.map(row => ({
            id: row.id,
            name: row.name,
            start: row.start,
            end: row.end,
            status: row.status ? '완료' : '미완료',
            count: row.count,
            writer: row.writer
        }));

        return {
            data: historyData,
            total: response.data.total_count // 전체 데이터 개수 반환
        };
    } catch (error) {
        console.error('Error fetching history data:', error);
        return { data: [], total: 0 }; // 에러 발생 시 빈 배열과 0 반환
    }
}
