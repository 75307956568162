// Elasticsearch Output Type
import {Grid, TextField } from "@mui/material";
import React from "react";

export function BaseOutput(props: any) {
    return (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2}}>
            <Grid container spacing={1}>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <TextField
                        required
                        fullWidth
                        id='output_table'
                        label='Output Table'
                        value={props.output.table}
                        onChange={props.handleOutput}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}


