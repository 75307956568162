import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Since from './components/Since/Since';
import Config from './components/Config/Config';
import RegisterConfig from './components/Config/RegisterConfig';
import Process from './components/Process/Process';
import Code from './components/Code/Code';
import RegisterCode from "./components/Code/RegisterCode";
import MainBox from './components/BoxComponent/MainBox';
import Login from './components/Login/Login';
import RepositoryConfig from './components/Repository/RepositoryConfig';
import RepositoryDetail from "./components/Repository/RepositoryDetail";
import {isUserLoggedIn} from './api/authenticateAPI';
import {GuideView} from "./components/Config/Mapping/xAPI/Guide";
import UserConfig from "./components/User/userConfig";
import HistoryConfig from "./components/History/historyConfig";
import {ErrorPage} from "./components/Error/PageError";


function App() {
    if (!isUserLoggedIn()) {
        return <Login />
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<MainBox><Config /></MainBox>}/>
                <Route path='/update_config/:configName' element={<MainBox><RegisterConfig /></MainBox>}/>
                <Route path='/process' element={<MainBox><Process /></MainBox>}/>
                <Route path='/since' element={<MainBox><Since /></MainBox>}/>
                <Route path='/code' element={<MainBox><Code /></MainBox>}/>
                <Route path='/update_code/:codeId' element={<MainBox><RegisterCode /></MainBox>}/>
                <Route path='/mapping' element={<MainBox><GuideView /></MainBox>}/>
                <Route path='/repository' element={<MainBox><RepositoryConfig/></MainBox>}/>
                <Route path='/repository/:repositoryName' element={<MainBox><RepositoryDetail/></MainBox>}/>
                <Route path='/user_view' element={<MainBox><UserConfig/></MainBox>}/>
                <Route path='/history_view' element={<MainBox><HistoryConfig/></MainBox>}/>
                <Route path='*' element={<ErrorPage></ErrorPage>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
