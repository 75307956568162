import {Grid, TextField, Select, MenuItem,SelectChangeEvent} from "@mui/material";
import React from "react";

export function APIInputAuth(props: any) {
    return (
        <>
        <Grid container spacing={1}>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <Select
                    fullWidth
                    id='input_api_auth_type'
                    value={props.input.auth_type}
                    onChange={props.handleType}>
                    <MenuItem value="No Auth">No Auth</MenuItem>
                    <MenuItem value="Basic Auth">Basic Auth</MenuItem>
                    <MenuItem value="Bearer Token">Bearer Token</MenuItem>
                </Select>
            </Grid>
        </Grid>
            {props.input.auth_type === 'Basic Auth' &&
                <APIInputAuthBasicAuth input={props.input.auth} handleInput={props.handleInput}></APIInputAuthBasicAuth>
            }
            {props.input.auth_type === 'Bearer Token' &&
                <APIInputAuthToken input={props.input.auth} handleInput={props.handleInput}></APIInputAuthToken>
            }
        </>
    )

}

function APIInputAuthBasicAuth(props: any){
    return (
        <Grid container spacing={1} sx={{mt: 1}}>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <TextField
                    fullWidth
                    label="Basic Auth Username"
                    id='input_api_auth_username'
                    value={props.input.username}
                    onChange={props.handleInput}/>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <TextField
                    fullWidth
                    type="password"
                    label="Basic Auth Password"
                    id='input_api_auth_password'
                    value={props.input.password}
                    onChange={props.handleInput}/>
            </Grid>
        </Grid>
    )
}

function APIInputAuthToken(props: any) {
    return (
        <Grid container spacing={1} sx={{mt: 1}}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                    fullWidth
                    label="Basic Auth Token"
                    id='input_api_auth_token'
                    value={props.input.token}
                    onChange={props.handleInput}/>
            </Grid>
        </Grid>
    )
}